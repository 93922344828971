<template>
    <div class="IconBox">
        <img 
            :src="require(`@/assets/icons/${icon}`)"
            :text="text"
            :style="getDirection()"
        >
        <p>{{text}}</p>
    </div>
</template>

<script>
export default {
    name: 'IconBox',
    props:{
        icon: String,
        text: String,
        iconDirection: String
    },
    data(){
        return{
            //
        }
    },
    methods: {
        getDirection(){
            if(this.iconDirection === 'horizontal'){
                return 'width: 87px';
            } else if(this.iconDirection === 'vertical'){
                return 'width: 71px';
            } else{
                return 'width: 71px';
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.IconBox{
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 148px;
    align-items: center;
    justify-content: center;

    background-color: $c-window-bg;
    border: $main-borders;
    box-shadow: $shadow;
    transition: 0.15s;
    &:active{
        box-shadow: none;
    }

    p{
        font-size: 1.25rem;
        margin-top: 5px;
    }
}

</style>