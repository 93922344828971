<template>
    <section id="Curriculum">
        <div class="container-curr">
            <!-- START CONTAINER LEFT -->
            <div class="container-left">
                <section id="about-me" class="content-box">
                    <div class="top-bar">
                        <div class="myportrait">
                            <img src="@/assets/imgs/myportrait.jpg" alt="">
                        </div>
                    </div>
                    <div class="content-box-center">
                        <div class="about-me-h">
                            <h2>Ciao! Sono Aris Goi</h2>
                            <p>UX, UI, Designer & Web Developer</p>
                        </div>
                        <p>
                            Laureato in <strong>Graphic Design & Art Direction</strong> presso <a href="https://www.naba.it/it" target="_blank">NABA</a>.
                            <br>Specializzato in <strong>sviluppo Front-End</strong> presso <a href="https://boolean.careers/" target="_blank">Boolean</a>.
                        </p>
                        <br>
                        <p>
                            Sono una <strong>persona Creativa</strong> e <strong>appassionato di Tecnologia</strong> da sempre.
                            <br>Studiando <strong>Graphic Design & Art Direction</strong> sono rimasto catturato dal mondo <strong>dell’editoria e del Web Design.</strong> Così ho deciso di seguire questo percorso intraprendendo il corso Boolean per Web Developer.
                            <br>Ho voluto intraprende queste due strade (Design e Coding) e unirle, per completare la mia figura professionale, così da farle comunicare al meglio in un progetto completo.
                        </p>
                    </div>
                </section>

                <section id="contacts" class="content-box">
                    <div class="top-bar">
                        <div class="icon-box">
                            <img src="@/assets/icons/info-icon.svg" alt="">
                        </div>
                        <h3>Informazioni Personali</h3>
                    </div>
                    <div class="content-box-center">
                        <div class="col_left">
                            <ul>
                                <li><strong>Telefono:</strong></li>
                                <li><strong>E-mail:</strong></li>
                                <li><strong>Portfolio:</strong></li>
                            </ul>
                        </div>
                        <div class="col_right">
                            <ul>
                                <li><a class="p_link" href="tel:">+39 345 3251796</a></li>
                                <li><a class="p_link" href="mailto:&#x61;&#x72;&#x69;&#x73;&#x2e;&#x67;&#x6f;&#x69;&#x39;&#x37;&#x40;&#x67;&#x6d;&#x61;&#x69;&#x6c;&#x2e;&#x63;&#x6f;&#x6d;">aris.goi97@gmail.com</a></li>
                                <li><a class="p_link" href="www.arisgoi.it">www.arisgoi.it</a></li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="skills" class="content-box">
                    <div class="top-bar">
                        <div class="icon-box">
                            <img src="@/assets/icons/skills-icon.svg" alt="">
                        </div>
                        <h3>Conoscenze Tecniche</h3>
                    </div>
                    <div class="content-box-center">
                        <div class="block-list">
                            <h3>Graphic Design:</h3>
                            <ul>
                                <li>
                                    <img src="@/assets/icons/skills/illustrator.svg" alt="">
                                    <div class="skill_more_info">Adobe Illustrator</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/indesign.svg" alt="">
                                    <div class="skill_more_info">Adobe InDesign</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/photoshop.svg" alt="">
                                    <div class="skill_more_info">Adobe Photoshop</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/premiere.svg" alt="">
                                    <div class="skill_more_info">Adobe Premiere</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/cc.svg" alt="">
                                    <div class="skill_more_info">Adobe Suite</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/sketch.svg" alt="">
                                    <div class="skill_more_info">Sketch</div>
                                </li>
                            </ul>
                        </div>
                        <div class="block-list">
                            <h3>Web Development:</h3>
                            <ul>
                                <li>
                                    <img src="@/assets/icons/skills/html.svg" alt="">
                                    <div class="skill_more_info">Html 5</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/css.svg" alt="">
                                    <div class="skill_more_info">CSS 3</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/javascript.svg" alt="">
                                    <div class="skill_more_info">Javascript</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/php.svg" alt="">
                                    <div class="skill_more_info">php</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/laravel.svg" alt="">
                                    <div class="skill_more_info">Laravel</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/vueJS.svg" alt="">
                                    <div class="skill_more_info">Vue.JS</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/sass.svg" alt="">
                                    <div class="skill_more_info">Sass</div>
                                </li>
                                <li>
                                    <img src="@/assets/icons/skills/mysql.svg" alt="">
                                    <div class="skill_more_info">MySQL</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
            <!-- START CONTAINER RIGHT -->
            <div class="container-right">
                <section id="esperienza" class="content-box">
                    <div class="top-bar">
                        <div class="icon-box">
                            <img src="@/assets/icons/work-icon.svg" alt="">
                        </div>
                        <h3>Esperienza Professionale</h3>
                    </div>
                    <div class="content-box-center">
                        <ul>
                            <li>
                                Febbraio 2022 - In corso<br>
                                <strong>Web Designer & Developer, Jam Area,</strong><br>
                                Corso Europa 50 - Verbania, <a class="p_link" href="https://www.jamarea.com">jamarea.com</a>
                            </li>
                            <li>
                                Giugno 2021 - 6 mesi (600 ore)<br>
                                <strong>Jr. Full Stack Developer, Boolean Careers,</strong><br>
                                Alcuni progetti e una WebApp di delivery
                            </li>
                            <li>
                                Giugno 2020<br>
                                <strong>Stage, Naba, Accademia di belle arti,</strong><br>
                                Via Carlo Darwin 20 - Milano,<br>
                                Collaborando con Berlucchi e Phoenix Informatica
                            </li>
                            <li>
                                Luglio 2019<br>
                                <strong>Workshop, Naba, Accademia di belle arti,</strong><br>
                                Via Carlo Darwin 20 - Milano,<br>
                                Creazione sito universitario per Workshop
                            </li>
                            <li>
                                Marzo 2016<br>
                                <strong>Stage, Jam Area, Agenzia Grafica,</strong><br>
                                Corso Europa 50 - Verbania, Graphic and Web Designer
                            </li>
                        </ul>
                    </div>
                </section>

                <section id="istruzione" class="content-box">
                    <div class="top-bar">
                        <div class="icon-box">
                            <img src="@/assets/icons/school-icon.svg" alt="">
                        </div>
                        <h3>Istruzione</h3>
                    </div>
                    <div class="content-box-center">
                        <ul>
                            <li>
                                Giugno 2021 - Dicembre 2021<br>
                                <strong>Boolean, Corso FullStack Developer,</strong><br>
                                Diploma di partecipazione al Corso Boolean per FullStack Developer
                            </li>
                            <li>
                                Settembre 2017 - Marzo 2021<br>
                                <strong>NABA, Nuova Accademia di belle arti,</strong><br>
                                Laurea in Graphic Design & Art Direction
                            </li>
                            <li>
                                Settembre 2012 - Luglio 2017<br>
                                <strong>Istituto Tecnico Ferrini,</strong><br>
                                Diploma in Grafica e Comunicazione
                            </li>
                        </ul>
                    </div>
                </section>

                <section id="hobby" class="content-box">
                    <div class="top-bar">
                        <h3>Hobby e Passioni</h3>
                    </div>
                    <div class="content-box-center">
                        <ul>
                            <li>Informatica</li>
                            <li>Design</li>
                            <li>AirSoft / Giochi di Strategia</li>
                            <li>Camping</li>
                        </ul>
                    </div>
                </section>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Curriculum',
    metaInfo: {
        title: 'Curriculum',
        meta: [{
            name: 'robots',
            content: 'noindex',
        }]
    },
    props:{
        // msg: String
    },
    data(){
        return{
            //
        }
    },
    // created(){
    //     if(this.$router.history.current.name == 'Curriculum'){$emit(!isNotCurriculum)}
    // },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

// ######
// CONTAINERS
// ######
.container-curr{
    display: flex;
    justify-content: flex-start;
    padding-top: 40px;

    .container-left, .container-right{
        width: 100%;
        padding: 0 15px;
        margin: 0;
    }
    // .container-left{
    //     background-color: red;
    // }
    // .container-right{
    //     background-color: blue;
    // }
}

// ######
// COMMONS
// ######
a{text-decoration: underline;}

.content-box-center > :where(p, ul, li){
    @include sans-light;
    font-size: 1.125rem;
}

.content-box{
    display: flex;
    flex-direction: column;
    border: $main-borders;
    background-color: $c-window-bg;
    width: 100%;
    margin-top: 40px;
    position: relative;

    &:first-child{
        margin-top: 0;
    }
}
.content-box-center{
    padding: 40px 60px;
}
.top-bar{
    display: flex;
    align-items: center;
    position: relative;
    height: 70px;
    border-bottom: $main-borders;

    h3{
        padding: 0 20px;
        font-size: 25px;
        font-weight: 500;
    }

    .icon-box{
        width: 70px;
        height: 100%;
        @include flex-center;
        border-right: 5px solid black;

        img{
            height: 55%;
            max-width: 65%;
        }
    }
}

// ######
// ABOUT ME
// ######
#about-me{
    .myportrait{
        width: 150px;
        height: 150px;
        border: $main-borders;
        position: absolute;
        top: 20px;
        left: 60px;
        overflow: hidden;
        @include img-fit-cover;
    }

    .about-me-h{
        margin-top: 85px;
        margin-bottom: 25px;

        h2{
        font-size: 2.5rem;
        }
    }
}

// ######
// CONTACTS
// ######
#contacts{
    .content-box-center{
        display: flex;

        [class^="col_"] li{
            list-style: none;
            margin-bottom: 5px;
            font-weight: 300;
        }

        .col_left{
            text-align: right;
            padding-right: 5px;
            li{
                font-weight: 500;
            }
        }
        .col_right{
            flex-grow: 1;
            padding-left: 5px;
        }
    }
}

// ######
// SKILLS
// ######
#skills{
    .block-list{
        h3{
            margin: 0 0 0 -20px;
            font-size: 25px;
            font-weight: 500;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            padding: 10px 0;
            li{
                position: relative;
                margin: 10px 12px;
                padding: 0 -12px;

                img{
                    height: 30px;
                }

                div{
                    display: none;
                }

                &:hover div{
                    display: block;
                    position: absolute;
                    bottom: 110%;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: white;
                    padding: 10px;
                    border: $main-borders;
                }
            }
        }
    }
}


// ######
// ESPERIENZA PROFESSIONALE & ISTUZIONE
// ######
#esperienza, #istruzione{
    .content-box-center{
        li{
            list-style: none;
            margin: 15px 0;
            position: relative;

            &:first-child{
                margin-top: 0;
            }
            &:last-child{
                margin-bottom: 0;
            }

            &:before{
                content: '◆';
                position: absolute;
                top: -6px;
                left: -28px;
                font-size: 25px;
            }
        }
    }
}

</style>