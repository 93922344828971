import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import Home from '../views/Home.vue'
import InfoContact from '../views/InfoContact.vue'
import MiniGames from '../views/MiniGames.vue'
import Curriculum from '../views/Curriculum.vue'
// import UnderCostruction from '../views/UnderCostruction.vue'
import NotFound404 from '../views/NotFound404.vue'


Vue.use(VueRouter)
Vue.use(Meta);

const routes = [
  {
    path: '*',
    name: 'NotFound404',
    component: NotFound404
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/curriculum',
    name: 'Curriculum',
    component: Curriculum
  },
  {
    path: '/info-contact',
    name: 'Contact',
    component: InfoContact
  },
  {
    path: '/minigames',
    name: 'MiniGames',
    component: MiniGames
  },
  {
    path: '/works',
    name: 'Works', //change also in Works Component
    component: () => import( '@/views/Works.vue' ),
    children: [ // Works List
      {
        id: 11,
        path: 'gnammy',
        name: 'proj-gnammy',
        component: () => import( '@/views/Works/ProjGnammy.vue' ),
        title: 'Gnammy App',
        coverName: 'proj-gnammy/cover.webp',
        date: 'july, 2021'
      },
      {
        id: 10,
        path: 'pressule-reobject',
        name: 'proj-reobject',
        component: () => import( '@/views/Works/ProjReObject.vue' ),
        title: 'Pressule',
        coverName: 'proj-reobject/cover.webp',
        date: 'march, 2021'
      },
      {
        id: 9,
        path: 'studio-multidisciplinare-samarati',
        name: 'proj-samarati',
        component: () => import( '@/views/Works/ProjSamarati.vue' ),
        title: 'Studio Multidisciplinare - Roberta Samarati',
        coverName: 'proj-samarati/cover.webp',
        date: 'September, 2020'
      },
      {
        id: 8,
        path: 'veritas-winery',
        name: 'proj-veritas',
        component: () => import( '@/views/Works/ProjVeritas.vue' ),
        title: 'Veritas - Winery',
        coverName: 'proj-veritas/cover.webp',
        date: 'July, 2020'
      },
      {
        id: 7,
        path: 'tiku',
        name: 'proj-tiku',
        component: () => import( '@/views/Works/ProjTiku.vue' ),
        title: 'Tiku - Love App',
        coverName: 'proj-tiku/cover.webp',
        date: 'July 2019'
      },
      {
        id: 6,
        path: 'banksy-exhibition',
        name: 'proj-banksy-ex',
        component: () => import( '@/views/Works/ProjBanksy.vue' ),
        title: 'Banksy Exhibition',
        coverName: 'proj-banksy/cover.webp',
        date: 'February, 2019'
      },
      {
        id: 5,
        path: 'tratti-emotivi',
        name: 'proj-tratti-emotivi',
        component: () => import( '@/views/Works/ProjTrattiEmotivi.vue' ),
        title: 'Tratti Emotivi',
        coverName: 'proj-tratti-emotivi/cover.webp',
        date: 'August, 2018'
      },
      {
        id: 4,
        path: 'art-of-typography',
        name: 'proj-art-typography',
        component: () => import( '@/views/Works/ProjTypography.vue' ),
        title: 'Art of Typography',
        coverName: 'proj-art-typography/cover.webp',
        date: 'May, 2017'
      },
      {
        id: 3,
        path: 'snapchat-geofilter',
        name: 'proj-snapchat',
        component: () => import( '@/views/Works/ProjSnapchat.vue' ),
        title: 'Snapchat Geofilter',
        coverName: 'proj-snapchat/cover.webp',
        date: 'May, 2017'
      },
      // {
      //   id: 2,
      //   path: 'the-rogers-adventure',
      //   name: 'proj-rogers-adventure',
      //   component: () => import( '@/views/Works/Template.vue' ),
      //   title: 'The Roger\'s Adventure',
      //   coverName: 'proj-rogers-adventure/cover.png',
      //   date: 'July, 2016'
      // },
      // {
      //   id: 1,
      //   path: 'la-primaria',
      //   name: 'proj-la-primaria',
      //   component: () => import( '@/views/Works/ProjLaPrimaria.vue' ),
      //   title: 'Branding La Primaria',
      //   coverName: 'proj-la-primaria/cover.png',
      //   date: 'June, 2016'
      // },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Define page name
// router.beforeEach((to, from, next) => {
//   document.title = `Aris Goi - ${to.name}`;
//   next()
// })

export default router
