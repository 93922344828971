<template>
  <section id="Home" class="container_home">
    <div class="i-box">
      <router-link :to="{name: 'Works'}">
        <IconBox
          class="icon-boxes"
          icon="icon_file.svg"
          iconDirection="vertical"
          text="Works.page"
        />
      </router-link>

      <router-link :to="{name: 'Contact'}">
        <IconBox
          class="icon-boxes"
          icon="icon_mail.svg"
          iconDirection="horizontal"
          text="Contact.page"
        />
      </router-link>
    </div>

<!-- TRY -->
    <div class="advertising-box">
        <div class="top-bar">
            <p class="alert-icon-box">Advertising</p>
        </div>
        <div class="main-content">
            <div class="hero-window-center">
                <h2>Ciao! Sono Aris</h2>
                <h4 class="wordCarousel">
                  <div>
                      <!-- Use classes 2,3, or 4 to match the number of words -->
                      <ul class="flip4">
                          <li>UX Designer</li>
                          <li>Web Developer</li>
                          <li>Web Designer</li>
                          <li>Visual Designer</li>
                      </ul>
                  </div>
                </h4>
            </div>
            <div class="hero-window-foot">
              <router-link :to="{name: 'Works'}" class="btn-classic">My Works</router-link>
              <router-link :to="{name: 'Contact'}" class="btn-classic">Info & Contacts</router-link>
            </div>
        </div>
    </div>


  </section>
</template>

<script>
import IconBox from '@/components/small/IconBox.vue'
export default {
  name: 'Home',
  components:{
    IconBox,
  },
  metaInfo: {
    title: 'Home',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Ciao! Sono Aris Goi un designer e developer, questo è il mio Portfolio. Dagli un occhiata!',
    }],
  },
  mounted(){
    //
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.container_home{
    // padding: 200px 7px;
    @include flex-center;
    flex-wrap: wrap;
    height: calc(100vh - 175px - 16px);
}

.icon-boxes{
  margin: 30px
}

.advertising-box{
    @include flex-center;
    flex-direction: column;
    flex-grow: 1;
    max-width: 650px;

    border: 5px solid black;
    @include box-shadow;

    animation-name: lightFloating;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    .top-bar{
        height: 50px;
        width: 100%;
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: $c-03;
        border-bottom: 5px solid black;
        font-weight: 700;
        font-size: 20px;
    }

    .main-content{
        @include flex-center;
        flex-direction: column;
        width: 100%;
        min-height: 350px;
        background-color: $c-window-bg;
        text-align: center;
    }

    .hero-window-center{
      @include flex-center;
      flex-direction: column;
      flex-grow: 1;

      h2{
        position: relative;
        font-size: 45px;
        font-weight: 600;
        margin-bottom: 10px;
        
        &::after{
          content: '';
          height: 5px;
          width: 80%;
          background-color: black;
          position: absolute;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      h4{
        font-size: 25px;
        font-weight: 500;
      }
    }

    .hero-window-foot{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      a:hover{
        background-color: $c-02;
      }
    }
}

@media screen and (max-width: 730px){
  .container_home{
    height: calc(100vh - 40px);
    padding: 100px 7px;

    .i-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      order: 1;

      .icon-boxes{
        margin: 30px 20px 20px 0;
      }
    }

    .advertising-box{
      flex-grow: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px){
  .container_home{
    // flex-direction: column;
    padding: 40px 7px;

    .i-box{
      order: 1;
      margin-top: 30px;

      .icon-boxes{
        margin: 10px;
      }
    }

    .advertising-box{
      .main-content{
        min-height: 250px;
      }
      .hero-window-foot{
        display: none;
      }
    }
  }
}

</style>