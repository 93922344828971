<template>
    <section id="NotFound404">
        <div class="advertising-box">
            <div class="top-bar">
                <p class="alert-icon-box">
                    <img class="alert-icon" src="@/assets/icons/info-icon.svg" alt="">
                    Alert!
                </p>
            </div>
            <div class="main-content">
                <h3>- The page you requested cannot be found. -</h3>
                <h1>Error 404</h1>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NotFound404',
    metaInfo: {
        title: 'Works',
        meta: [
            {
                vmid: 'description',
                name: 'description',
                content: 'La pagina che stai cercando non esiste oppure non è stata trovata.',
            },
            {
                name: 'robots',
                content: 'noindex,nofollow'
            },
        ],
    },
}
</script>

<style scoped lang="scss">

@import '@/scss/_variables.scss';

#NotFound404{
    font-family: 'Chakra Petch', sans-serif;
    background-color: rgb(143, 143, 143);

    // background-image: url(../assets/bg-pattern.png);
    // background-size: 8px;
    // background-repeat: repeat;

    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;

    padding: 0 15px;
}

.advertising-box{
    @include flex-center;
    flex-direction: column;
    max-width: 650px;
    margin: 0 auto;
    margin-top: 50vh;
    transform: translateY(-50%);

    border: 5px solid black;
    -webkit-box-shadow: 7px 7px 0px 0px rgba(0,0,0,0.45);
    -moz-box-shadow: 7px 7px 0px 0px rgba(0,0,0,0.45);
    box-shadow: 7px 7px 0px 0px rgba(0,0,0,0.45);

    animation-name: float404;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    .top-bar{
        height: 50px;
        width: 100%;
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: #FFDF8B;
        border-bottom: 5px solid black;
        font-weight: 700;
        font-size: 20px;
    }

    .main-content{
        @include flex-center;
        flex-direction: column;
        width: 100%;
        min-height: 350px;
        background-color: white;
        text-align: center;

        img.logo{
            margin: 30px 0 ;
            height: 45px;
            opacity: 0.7;
        }

        h3{
            font-size: 25px;
            // margin-bottom: -10px;
        }
        h1{
            font-size: 50px;
            line-height: 45px;
            margin-top: 15px;
        }
        
    }
}

@keyframes float404{
    0%{
        transform: translateY(-45%);
    }
    50%{
        transform: translateY(-50%);
    }
    100%{
        transform: translateY(-45%);
    }
}

// ALERT ICON
.alert-icon-box{
    display: flex;
    align-items: center;
    .alert-icon{
        height: 28px;
        margin-right: 20px;
        display: inline-block;
        animation-name: bell-ring;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }
}

</style>