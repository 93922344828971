<template>
  <section id="Contact">
    <section id="about-me" class="container">
        <div class="content-box">
            <div class="top-bar">
                <div class="myportrait">
                    <img src="@/assets/imgs/myportrait.jpg" alt="">
                </div>
            </div>
            <div class="content-box-center">
                <div class="about-me-h">
                    <h2>Ciao! Sono Aris Goi</h2>
                    <p>Laureato in <strong>Graphic Design & Art Direction</strong> presso <a href="https://www.naba.it/it" target="_blank">NABA</a>.
                    <br>Specializzato in <strong>sviluppo Front-End</strong> presso <a href="https://boolean.careers/" target="_blank">Boolean</a>.</p>
                </div>
                <p>
                    <strong>Ciao sono Aris,</strong> una persona Creativa e da sempre appassionata di Tecnologia e Design.
                    <br>Sono pigro, ma quel tipo di pigrizia che definirei meglio con la frase: "essere creativo per essere produttivo", perché cerco sempre il modo migliore per risolvere le task ottimizzando il tempo.
                    <br><br><strong>Sul mio portfolio puoi fare una scelta!</strong> Guardare i miei lavori nella sezione <router-link :to="{name: 'Works'}">"My Works"</router-link> oppure come spesso piace fare a molti, puoi <em>procastinare fortissimo!!!</em>
                    Se hai scelto la seconda opzione posso darti una mano, ho creato una pagina <router-link :to="{name: 'MiniGames'}">"Minigames"</router-link>!
                    Lì potrai passare il tempo con minigiochi creati con da me utilizzando Javascript.
                </p>
            </div>
        </div>
    </section>
    <section id="contact-me" class="container">
        <!-- top bar -->
        <div class="content-box">
            <div class="top-bar">
                Contact me
            </div>
            <div class="content-box-center">
                <!-- form -->
                <form ref="form" @submit.prevent="sendEmail">
                    <div id="box-user_name">
                      <label for="name">Nome:</label>
                      <input v-model="name" type="text" id="user_name" name="user_name" placeholder="Your name...">
                    </div>
                    
                    <div id="box-user_email">
                      <label for="email">Email:</label>
                      <input v-model="email" type="email" id="user_email" name="user_email" placeholder="Your Email Address...">
                    </div>

                    <div id="box-user_message">
                      <label for="message">Messaggio:</label>
                      <textarea v-model="message" type="textarea" id="user_message" name="user_message" placeholder="Your messagge..."></textarea>
                    </div>

                    <input class="send btn-classic" type="submit" value="Send">
                </form>
            </div>
        </div>
    </section>
  </section>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: 'Contact',
  metaInfo: {
    title: 'Info & Contact',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Ciao sono Aris Goi, ecco qui una mia breve presentazione e tutto ciò che ti serve per contattarmi!',
    }],
  },
  props:{
    // msg: String
  },
  data(){
    return{
      name: null,
      email: null,
      message: null,

    // Form Validation
      nameInvalid: false,
      emailInvalid: false,
      messageInvalid: false,

    // description translate
      translate: false,
    }
  },
  methods: {
    sendEmail() {
    // Cleaning
      this.nameInvalid = false;
      this.emailInvalid = false;
      this.messageInvalid = false;
      document.getElementById('box-user_name').classList.remove('invalid');
      document.getElementById('box-user_email').classList.remove('invalid');
      document.getElementById('box-user_message').classList.remove('invalid');

      // First Validation
      if (!this.name || this.name.length < 3) {
        this.nameInvalid = true;
        document.getElementById('box-user_name').classList.add('invalid');
      }
      if (!this.email || this.email.length < 8 || !this.email.includes('@')) {
        this.emailInvalid = true;
        document.getElementById('box-user_email').classList.add('invalid');
      }
      if (!this.message || this.message.length < 20) {
        this.messageInvalid = true;
        document.getElementById('box-user_message').classList.add('invalid');
      }

      // Second Validatio
      if(!this.nameInvalid && !this.emailInvalid && !this.messageInvalid){
        // Email Sending //settings on www.emailjs.com
        emailjs.sendForm('service_yf5a5zs', 'template_vuxzb6d', this.$refs.form, 'user_VKBBc3PS8RylhzC0BDmPk')
        .then((result) => {
            console.log('Email Sending: SUCCESS!', result.text);
        }, (error) => {
            console.log('Email Sending: FAILED...', error.text);
            alert('Si è verificato un problema durante l\'invio dell\'e-mail! Riprova più tardi.')
        });

        alert("L'email è stata inviata");
        this.$router.push({name: 'Home'})
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
// Form Validation
div[id^="box-user_"].invalid{
  position: relative;

  input,textarea{
    background-color: $c-03 !important;
    color: red !important;

    &::placeholder{
      color: red !important;
    }
  }

  &::after{
    content: 'Invalid Input';
    max-width: 40%;
    z-index: 2;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 20px;
    @include flex-center;

    background-color: $c-01;
    border: $main-borders;
  }
}
div#box-user_message.invalid::after{content: 'Message is short or Invalid';}
div#box-user_name.invalid::after{content: 'Invalid Name';}
div#box-user_email.invalid::after{content: 'Invalid Email';}

// STYLE
.top-bar{
  display: flex;
  align-items: center;
  position: relative;
  height: 85px;
  border-bottom: $main-borders;
  padding: 0 20px;
  font-size: 28pt;
  font-weight: 500;
}

.content-box{
  display: flex;
  flex-direction: column;
  border: $main-borders;
  background-color: $c-window-bg;
  width: 100%;
  margin-top: 54px;
  position: relative;
}
.content-box-center{
  padding: 40px 60px;
}

#about-me{
  a{text-decoration: underline;}
  .myportrait{
    width: 150px;
    height: 150px;
    border: $main-borders;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    @include img-fit-cover;
  }

  .about-me-h{
    margin-top: 80px;
    margin-bottom: 25px;
    text-align: center;

    h2{
      font-size: 2.5rem;
    }
  }

  .content-box-center > p{
    @include sans-light;
    font-size: 1.125rem;
  }
}

#contact-me{
  form{
    padding: 10px 0;
    display: flex;
    flex-direction: column;

    div{
      @include flex-center;
      margin: 10px 0;
      flex-grow: 1;
    }

    label{
      width: 90px;
      margin-right: 15px;
      text-align: right;
    }

    input, textarea {
    // force remove border-radius
      border-radius: 0;
      -webkit-appearance: none;
    }

    input:not(.send), textarea{
      flex-grow: 1;
      padding: 5px 20px;
      background-color: #9e9e9e;
      border: $main-borders;

      color: white;
      @include sans-medium;
      font-size: 16px;

      &::placeholder{
        color: white;
      }
    }
    input:not(.send){
      min-height: 40px;
    }
    textarea{
      min-height: 90px;
    }

    .send{
      max-width: 100px;
      margin: 20px auto 0 auto;
      background-color: $c-02;
      transition: 0.2s;
    }
    .send:hover{
      box-shadow: 3px 3px 0px rgba(0,0,0,0.45);;
    }
  }
}

@media screen and (max-width: 540px){
  #contact-me{
    .content-box-center{
      padding: 20px;
    }
    form{
      div{
        flex-direction: column;
        align-items: flex-start;

        label{
          text-align: left;
          margin-bottom: 5px;
        }

        input, textarea{
          width: 100%;
        }
      }
    }
  }
}

</style>
