<template>
  <header>
  <!-- NAV BEGIN -->
    <nav :class="`${navClass} ${isOpen ? 'open' : null}`">

      <div class="left">
        <img v-if="bigNav == false" class="logo" src="@/assets/logo.png" alt="">

        <!-- divider -->
        <hr :class="isOpen ? 'show' : 'hide'">

        <!-- Links list of pages -->
        <ul :class="isOpen ? 'show links' : 'hide links'">
          <li :class="$route.name == 'Home' ? 'current' : null">
            <router-link :to="{name: 'Home'}">
              <span v-if="bigNav == false">Home</span>
              <img v-if="bigNav == true" class="logo" src="@/assets/logo.png" alt="">
            </router-link>
          </li>
          <li :class="$route.name == 'Works' ? 'current' : null">
            <router-link :to="{name: 'Works'}">
              Works
            </router-link>
          </li>
          <li :class="$route.name == 'MiniGames' ? 'current' : null">
            <router-link :to="{name: 'MiniGames'}">
              MiniGames
            </router-link>
          </li>
          <li :class="$route.name == 'Contact' ? 'current' : null">
            <router-link :to="{name: 'Contact'}">
              Info & Contact
            </router-link>
          </li>
        </ul>

        <!-- divider -->
        <hr :class="isOpen ? 'show' : 'hide'">

        <!-- Links list of Socials -->
        <div :class="isOpen ? 'show socials' : 'hide socials'">
          <h3>Socials</h3>
          <ul>
            <li class="social">
              <a :href="instagram" target="_blank">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
              </a>
            </li>
            <li class="social">
              <a :href="github" target="_blank">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>GitHub</title><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/></svg>
              </a>
            </li>
            <li class="social">
              <a :href="linkedin" target="_blank">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
              </a>
            </li>
          </ul>
        </div>

      </div>

      <!-- right yellow decoration & Button for Hamburger Menu -->
      <div class="right">
        <button class="hamburger" @click="isOpen ? isOpen = false : isOpen = true"><div :class="isOpen ? 'close' : null"></div></button>
      </div>

    </nav>

  </header>
</template>

<script>
export default {
  name: 'Header',
  data(){
    return{
      isOpen: false, //hamburger menu is open ?
      navClass: null, //which class have navbar ? 
      bigNav: false, //the screen and navbar are in big version ?

      // Social Links
      instagram: 'https://www.instagram.com/arisgoi.visual/',
      github: 'https://github.com/ArisGoi',
      linkedin: 'https://www.linkedin.com/in/aris-goi/',
    }
  },
  methods: {
    onResize(){
      let bigScreen = window.matchMedia("(min-width: 800px)").matches; //the screen is big then 800px ? (boolean)

      this.isOpen = false;
      
      if(bigScreen){
        this.navClass = 'big-navbar';
        this.bigNav = true;
      }else{
        this.navClass = 'small-navbar';
        this.bigNav = false;
      }
    }
  },
  watch: {
    // Al change della route chiude il menu
    $route(){
      this.isOpen = false;
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

nav{
  border-top: $main-borders;
  border-bottom: $main-borders;
  display: flex;
  height: 65px;
  background-color: $c-01;
  @include sans-semibold;
  overflow: hidden;
  transition: .5s;

  &.open{
    height: 100vh;
  }
}

// BIG NAVBAR
nav.big-navbar{
  font-size: 1.5625rem;

  .left{
    width: 100%;
    display: flex;

    hr{
      display: none;
    }

    ul{
      opacity: 1;
      display: flex;
      list-style-type: none;
      width: 100%;
    }

    li.current{
      background-color: $c-03;
    }
    li:hover{
      background-color: $c-02;
    }

    a{
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 0 15px;
      border-right: $main-borders;
    }

    img.logo {
      // height: 85%;
      height: 43px;
      max-width: 80px;
    }

    .socials {
      opacity: 1;
      h3{display: none;}
      ul{
        height: 100%;
        li{
          a{
            border-left: $main-borders;
            border-right: none;
          }
    
          svg{
            height: 30px;
            max-width: 60px;
          }
        }
      }
    }
  }

  .right{
    display: none;
  }
}


// SMALL NAVBAR
nav.small-navbar{
  .hide{
    z-index: -1;
  }
  
  .left{
    position: relative;
    height: 100%;
    padding: 0 20px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;

    hr{
      transition: 0.2s;
      margin: 25px 0px;
      border: none;
      border-top: 2px solid $black-text;
    }

    ul{
      transition: 0.3s;
      list-style: none;
      a{
        font-size: 1.5625rem;
        text-transform: uppercase;
      }
      li{
        text-align: right;
      }
    }
    ul.links li{
      position: relative;
      padding: 10px 0px;

      &::after{
        transition: 0.3s;
        z-index: 1;
        content: '';
        height: 5px;
        width: 37px;
        display: block;
        position: absolute;
        right: -75px;
        top: 50%;
        transform: translateY(-50%);

        background-color: $black-text;
      }
    }

    .socials{
      transition: 0.3s;
      ul{
        display: flex;
        justify-content: flex-end;

        li{
          padding: 5px;
          margin: 5px;

          &:last-of-type{
            padding-right: 0;
            margin-right: 0;
          }

          svg{
            height: 1.75rem;
          }
        }
      }
    }
  }

  .right{
    position: relative;
    height: 100%;
    background-color: $c-03;
    border-left: $main-borders;
    width: 67px;
  }

  img.logo {
    height: 40px;
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
  }
  button.hamburger{
    height: 3.75rem;
    width: 100%;
    border: none;
    border-bottom: $main-borders;
    background: transparent;

    @include flex-center;
    // Hamburger Icon Settings
    $ham-icon-height: 5px;
    $ham-icon-width: 50%;
    $ham-icon-transition: 0.3s;

    // Hambuger Icon
    div{
      position: relative;
      height: $ham-icon-height;
      width: $ham-icon-width;
      background-color: $black;
      transition: calc($ham-icon-transition - 0.2s);
      
      &::before, &::after{
        content: '';
        position: absolute;
        left: 0;
        height: $ham-icon-height;
        width: 100%;
        display: block;
        background-color: $black;
        transition: $ham-icon-transition;
      }
      &::before{
        top: -12px;
      }
      &::after{
        bottom: -12px;
      }

      &.close{
        background-color: transparent;
        &::before{
          top: 50%;
          transform: translateY(-50%) rotate(-135deg);
        }
        &::after{
          bottom: 50%;
          transform: translateY(50%) rotate(135deg);
        }
      }
    }
  }
}

</style>