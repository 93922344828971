<template>
    <footer id="Footer">
        <!-- <p>This Portfolio it's made by me, with Vue.js... and love, obviously!</p> -->
        <p>© 2022 Aris Goi</p>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    props:{
        // msg: String
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

footer{
    text-align: center;
    background-color: $c-window-bg;
    border: $main-borders;
    max-width: 1200px;
    margin: 50px auto 15px auto;
    padding: 15px;

    a{
        text-decoration: underline;
    }
}

</style>