<template>
  <div id="app" style="padding-top: 65px;">
    <Header/>
    
    <router-view/>

    <Footer/>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  created(){
    // vv- disable Right-click -vv
    window.addEventListener('contextmenu', function (e) { 
      // do something here...
      e.preventDefault(); 
    }, false);
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'AG',
    // all titles will be injected into this template
    titleTemplate: '%s | Aris Goi Portfolio',

    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Hi! I\'m designer and developer and this is My Portfolio',
      },
      {
        name: 'keywords',
        content: 'aris, goi, graphic designer, design, art director, NABA, web design, web developer, online portfolio, siti web, verbania, html, css, vue.js, vue, php, laravel',
      }
    ],

    htmlAttrs: {
      lang: 'it',
    },
  }
}
</script>

<style lang="scss">

  @import '@/scss/style.scss';

</style>
