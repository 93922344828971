<template>
    <section class="PageTitle">
        <h1>{{title}}</h1>
        <p v-if="subtitle != null" v-html="subtitle"></p>
    </section>
</template>

<script>
export default {
    name: 'PageTitle',
    props:{
        title: String,
        subtitle: String
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.PageTitle{
    margin: 40px auto 15px auto;
    max-width: 1200px;
    // height: 85px;

    padding: 15px 30px 25px 30px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;

    background: $c-window-bg;
    box-shadow: $shadow;
    border: $main-borders;

    h1{
        @include f-top-title;
    }
    p{
        margin-top: 10px;
        font-weight: 400;
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 500px){
    .PageTitle h1{
        font-size: 1.75rem;
    }
}

</style>