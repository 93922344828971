<template>
  <div class="minigames">
    
    <page-title
      title="Minigames"
      subtitle="In questa pagina puoi trovare i Minigiochi realizzati da me con javascript nel mio tempo libero."
    />

    <div class="container">

      <div class="gameicon" @click="unlockSecret()" style="text-align: center; font-size: 30px; margin-top: 30px; margin-bottom: -30px; opacity: 0.25;">
        <i class="fas fa-gamepad"></i>
      </div>

      <div class="games-list">
        <ul>
      <!-- Minigames List -->

            <li @click="openGame('arisgoi.github.io/game-blackjack/')">
              <i class="fa-solid fa-dice"></i>
              <span>BlackJack <span>(work in progress)</span></span>
            </li>

          <li class="desktop_game" @click="openGame('arisgoi.github.io/bike-invaders/')">
            <i class="fa-solid fa-mosquito"></i>
            <span>Bike Invaders <span>(Need a keyboard)</span></span>
          </li>

          <li @click="openGame('arisgoi.github.io/game-maikuman-dice/')">
            <i class="fas fa-dice"></i>
            <span>MaikuMan Dice</span>
          </li>

          <li @click="openGame('arisgoi.github.io/js-campominato/')">
            <i class="fas fa-fish"></i>
            <span>Piranha's Lake - minesweeper <span>(Beta.2)</span></span>
          </li>
          
          <!-- Sectet games list -->
          <div class="secret-games" v-if="secret">

            <h2>Wow! Hai sbloccato una sezione segreta!</h2>
            <p>E ti starai chiedendo "Wow super bello! ma cosa ho vinto?"</p>
            <p>In realtà non hai vinto un premio tipo "gratta e vinci", però sarai sicuramente fortissimo perché sei riuscito a scovare <strong>la sezione in cui troverai i miei "work in progress" oppure minigames che ti vorrei consigliare.</strong></p>

            <li @click="openGame('www.edopedia.com/demo/pixeldefense')">
              <i class="fas fa-shield-alt"></i>
              <span>Pixel Defense <span>(consiglio)</span></span>
            </li>

            <li @click="openGame('cssgridgarden.com/#it')">
              <i class="fas fa-carrot"></i>
              <span>Grid Garden <span>(consiglio)</span></span>
            </li>

          </div>
          
      <!-- End - Minigames List -->
        </ul>
      </div>
    </div>

    <!-- MiniGames Frame -->
    <div v-if="fullFrame" class="fullFrame">
        <div class="top-frame-bar">
            Close this Game >> <button class="btn-close" @click="fullFrame = false"></button>
        </div>
        <iframe :src="`https://${gameUrl}`" frameborder="0"></iframe>
    </div>

  </div>
</template>

<script>
import PageTitle from '../components/small/PageTitle.vue'

export default {
  name: 'MiniGames',
  components: {
    PageTitle,
  },
  metaInfo: {
    title: 'MiniGames',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Una pagina ricca di videogiochi creati da me con Javascript. Puoi usarla per passare il tempo mentre ti annoi oppure mentre stai tentando di procastinare fortissimo!!',
    }],
  },
  data(){
    return{
      fullFrame: false,
      gameUrl: null,
      secret: false,
      secretCounter: 0,
    }
  },
  methods:{
    openGame(url){
      this.gameUrl = url;
      this.fullFrame = true;
    },
    unlockSecret(){
      this.secretCounter++;
      if(this.secretCounter >= 5){ this.secret = true; }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

@media screen and (max-width: 780px){
  .desktop_game{
    display: none;
  }
}

.secret-games{
  li{
    border-color: #3F8C83 !important;
    &:hover{
      border-color: #C8CC6E !important;
      i{color: #C8CC6E;}
    }
  }
  i{color: #3F8C83;}
}

.games-list{
  ul{
    list-style: none;
    margin-top: 60px;

    li{
      padding: 20px;
      margin: 20px 0;
      background-color: $c-window-bg;
      border: 4px solid black;
      @include sans-medium;
      font-size: 1.5625rem;

      cursor: $pointer;
      transition: 0.3s;
      &:hover{
        box-shadow: $shadow;
        border-color: $c-01;
      }

      i{
        margin-right: 10px;
      }

      span span{opacity: 0.3}
    }
  }
}

.fullFrame{
  background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    height: 100vh;
    width: 100%;

    .top-frame-bar{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 20px;
        height: 60px;
        background: $c-02;
        border-top: $main-borders;
        border-bottom: $main-borders;

        .btn-close{
            margin-left: 10px;
            animation-name: bell-ring;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }
    }

    iframe, .iframe-mag{
        width: 100%;
        height: calc(100% - 60px);
    }
}

</style>
